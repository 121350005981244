import { useState, useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';

export const useAppData = () => {
  const lng = useSelector((state: RootStateOrAny) => state.app.lng);
  const appData: any = useSelector(
    (state: RootStateOrAny) => state.app.appData
  );
  const [currentAppData, setCurrentAppData] = useState(null);

  useEffect(() => {
    if (lng && appData) {
      const currentData = appData?.[lng];

      setCurrentAppData(currentData);
    }
  }, [lng]);

  return currentAppData;
};
