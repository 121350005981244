export const PRODUCTION: string = 'production';

export const SET_LANGUAGE: string = 'SET_LANGUAGE';
export const STORE_ERROR: string = 'STORE_ERROR';
export const CLOSE_ERROR: string = 'CLOSE_ERROR';

export const APP_DATA: string = 'APP_DATA';

export const SET_THEME: string = 'SET_THEME';

export const STORE_INFO_DATA: string = 'STORE_INFO_DATA';
export const MAP_DATA: string = 'MAP_DATA';
export const EXPAND_DATA: string = 'EXPAND_DATA';

export const ON_SUCCESS_DROP: string = 'ON_SUCCESS_DROP';

export const STORE_GAME_DATA: string = 'STORE_GAME_DATA';

export const GAME_ITEMS: number = 10;

export const PIXEL_SIZE: number = window.screen.availWidth < 1920 ? 3 : 2;

// export const MAP_MAX_WIDTH: number = 2880 / 2;
export const SECTION_IMAGE_HEIGHT: number = 400 / PIXEL_SIZE;
