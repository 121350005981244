import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng } from '../common/enums';
import { ReactComponent as IconEN } from '../assets/svg/ico_en.svg';
import { ReactComponent as IconLT } from '../assets/svg/ico_lt.svg';
import { ReactComponent as IconLV } from '../assets/svg/ico_lv.svg';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { setLanguage } from '../common/app/actions';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const LNG_ICONS = {
  [Lng.LV]: IconLV,
  [Lng.LT]: IconLT,
  [Lng.EN]: IconEN,
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      languageContainerStyle: {
        position: 'relative',
        display: 'flex',
        // width: '100%',
        // height: '100%',
      },
      iconContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontFamily: FONTS.montserrat,
        color: COLORS.appGray,
        fontSize: `${48 / C.PIXEL_SIZE}px`,
        marginRight: `${80 / C.PIXEL_SIZE}px`,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: COLORS.white,
            transition: '0.5s ease',
          },
        },
      },
      iconStyle: {
        width: `${36 / C.PIXEL_SIZE}px`,
        marginRight: `${14 / C.PIXEL_SIZE}px`,
      },
      labelLightStyle: {
        color: COLORS.textBlack30,
      },
      activeLabelStyle: {
        fontWeight: FONT_WEIGHT.bold,
        color: COLORS.white,
        cursor: 'default',
        pointerEvents: 'none',
      },
      activeLabelLightStyle: {
        color: COLORS.textBlack,
      },
    })
);

const Language = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootStateOrAny) => state.app.lng);
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const newLng = event.currentTarget.getAttribute('data-lng');
    dispatch(setLanguage(newLng));
  };

  const getLanguage = (key, index) => {
    const Icon = LNG_ICONS[key];
    const label = key.toUpperCase();

    const isActive: boolean = key === lng;

    return (
      <div
        className={classes.iconContainerStyle}
        data-lng={key}
        key={index}
        onClick={onClick}
      >
        <Icon className={classes.iconStyle} />
        <span
          className={clsx(
            classes.labelStyle,
            isLightTheme && classes.labelLightStyle,
            isActive && classes.activeLabelStyle,
            isLightTheme && isActive && classes.activeLabelLightStyle
          )}
        >
          {label}
        </span>
      </div>
    );
  };

  return (
    <div className={classes.languageContainerStyle}>
      {Object.keys(LNG_ICONS).map(getLanguage)}
    </div>
  );
};

export default Language;
