import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Flickity from 'react-flickity-component';
import RightInfoObjectData from './RightInfoObjectData';
import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as CloseIcon } from '../assets/svg/ico_close.svg';
import { getPath } from '../common/utils/helpers';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useCityData } from '../common/hooks/useCityData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { clearData } from '../common/app/actions';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      objectContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        fontFamily: FONTS.montserrat,
      },
      closeStyle: {
        position: 'absolute',
        top: `${20 / C.PIXEL_SIZE}px`,
        right: `${50 / C.PIXEL_SIZE}px`,
        width: `${60 / C.PIXEL_SIZE}px`,
        height: `${60 / C.PIXEL_SIZE}px`,
        background: (props: any) => props.color,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      closeIconStyle: {
        width: `${40 / C.PIXEL_SIZE}px`,
      },
      objectWrapperStyle: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: `${100 / C.PIXEL_SIZE}px`,
        boxSizing: 'border-box',
        padding: `0 ${80 / C.PIXEL_SIZE}px`,
      },
      imageContainerStyle: {
        width: '100%',
        height: '100%',
        maxHeight: `${C.SECTION_IMAGE_HEIGHT}px`,
        // minWidth: '100%',
        // display: 'flex',
      },
      imageWrapperStyle: {
        // maxWidth: '100%',
        // maxHeight: `${C.SECTION_IMAGE_HEIGHT}px`,
        width: '100%',
        height: '100%',
        // display: 'flex',
        // flexDirection: 'column',
      },
      imageStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
      '@global': {
        '.carousel': {
          width: '100%',
          height: `${C.SECTION_IMAGE_HEIGHT}px`,
          display: 'flex',
          flex: 1,
          borderLeft: `1px solid ${COLORS.appBlue}`,
          borderRight: `1px solid ${COLORS.appBlue}`,
        },
        '.flickity-viewport': {
          width: '100%',
          height: '100% !important',
        },
        '.flickity-page-dots': {
          bottom: `${10 / C.PIXEL_SIZE}px !important`,
          display: (props: any) => (props.hasMultipleImages ? 'block' : 'none'),
        },
        '.dot': {
          border: `1px solid ${COLORS.white} !important`,
          background: 'none !important',
          opacity: '1 !important',
          width: `${14 / C.PIXEL_SIZE}px !important`,
          height: `${14 / C.PIXEL_SIZE}px !important`,
        },
        '.dot.is-selected': {
          border: 'none !important',
          background: `${COLORS.white} !important`,
        },
      },
    })
);

const RightInfoObject = (): JSX.Element => {
  const { cityData } = useCityData();
  const dispatch = useDispatch();
  const infoData = useSelector((state: RootStateOrAny) => state.app.infoData);

  const hasImages: boolean = Boolean(infoData?.images?.length);
  const hasMultipleImages: boolean = Boolean(infoData?.images?.length > 1);

  const color = cityData?.content?.color;
  const classes = useStyles({ color, hasMultipleImages });
  const { pathname } = useLocation();

  if (!infoData) {
    return null;
  }

  const removeLastDir = () => {
    const sanitizedPathname = pathname.replace(/\/$/, '');
    const pathArray = sanitizedPathname.split('/');
    pathArray.pop();
    return pathArray.join('/');
  };

  const onClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(clearData(C.STORE_INFO_DATA));
  };

  const getSlides = (currentImage, index) => {
    const imgUrl: string = `${urlPrefix}${currentImage.url}`;

    return (
      <div className={classes.imageWrapperStyle} key={index}>
        <img src={imgUrl} alt="" className={classes.imageStyle} />
      </div>
    );
  };

  const getImages = () => {
    const flickityOptions = {
      initialIndex: 0,
      draggable: hasMultipleImages,
      freeScroll: false,
      prevNextButtons: false,
      // adaptiveHeight: true,
      accessibility: true,
    };

    return (
      <Flickity
        className={'carousel'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        // disableImagesLoaded={false} // default false
        // reloadOnUpdate // default false
        // static // default false
        // ref={ref}
        // flickityRef={onFlickityLoad}
      >
        {infoData?.images?.map(getSlides)}
      </Flickity>
    );
  };

  return (
    <div className={classes.objectContainerStyle}>
      <div className={classes.closeStyle} onClick={onClose}>
        <CloseIcon className={classes.closeIconStyle} />
      </div>
      <div className={classes.objectWrapperStyle}>
        {hasImages && (
          <div className={classes.imageContainerStyle}>{getImages()}</div>
        )}
        <RightInfoObjectData
          data={infoData}
          hasImages={hasImages}
          color={color}
        />
      </div>
    </div>
  );
};

export default RightInfoObject;
