import * as C from '../constants';
import { Lng } from '../enums';

const INITIAL_STATE = Object.freeze({
  lng: Lng.LV,
  errorData: [],
  appData: null,
  isLightTheme: false,
  infoData: null,
  mapData: null,
  expandData: null,
  gameData: null,
});

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case C.SET_LANGUAGE: {
      return {
        ...state,
        lng: action.payload,
      };
    }
    case C.SET_THEME: {
      return {
        ...state,
        isLightTheme: action.payload,
      };
    }
    case C.STORE_ERROR: {
      const existingErrors = state.errorData;
      const latestError = [action.payload];
      const errorData = [...existingErrors, ...latestError];

      return {
        ...state,
        errorData,
      };
    }
    case C.CLOSE_ERROR: {
      const existingErrors = state.errorData;
      // eslint-disable-next-line
      const removedError = existingErrors.shift();
      const errorData = [...existingErrors];

      return {
        ...state,
        errorData,
      };
    }
    case C.APP_DATA: {
      return {
        ...state,
        appData: action.payload,
      };
    }
    case C.STORE_INFO_DATA: {
      return {
        ...state,
        infoData: action.payload,
      };
    }
    case C.MAP_DATA: {
      return {
        ...state,
        mapData: action.payload,
      };
    }
    case C.ON_SUCCESS_DROP: {
      const menuData = state.gameData?.menuData?.filter(
        (item) => item.content.uid !== action.payload
      );

      const gameData = {
        ...state.gameData,
        menuData,
      };

      return {
        ...state,
        gameData,
      };
    }
    case C.EXPAND_DATA: {
      return {
        ...state,
        expandData: action.payload,
      };
    }
    case C.STORE_GAME_DATA: {
      return {
        ...state,
        gameData: action.payload,
      };
    }
    default:
      return state;
  }
};
