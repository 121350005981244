import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import * as yaml from 'js-yaml';
import React, { useRef } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      infoContainerStyle: {
        position: 'relative',
        width: '100%',
        // maxHeight: '1075px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: FONTS.montserrat,
        flex: 1,
        '& > div:last-child': {
          flex: 1,
        },
      },
      infoWrapperStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '40px',
      },
      headerStyle: {
        width: '100%',
        textAlign: 'center',
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        lineHeight: `${46 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        color: COLORS.white,
        margin: `${40 / C.PIXEL_SIZE}px 0`,
        textTransform: 'uppercase',
        minHeight: `${90 / C.PIXEL_SIZE}px`,
      },
      headerLightStyle: {
        color: COLORS.textBlack,
      },
      labelStyle: {
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        lineHeight: `${42 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        // color: COLORS.appButtonsGray,
        marginBottom: `${20 / C.PIXEL_SIZE}px`,
        color: (props: any) => props.color,
      },
      labelLightStyle: {
        // color: COLORS.textBlack30,
      },
      textStyle: {
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        lineHeight: `${42 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
      },
      textLightStyle: {
        color: COLORS.textBlack,
      },
    })
);

const RightInfoObjectData = ({ data, hasImages, color }): JSX.Element => {
  const classes = useStyles({ color });
  const scrollRef = useRef<any>();

  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  const infoTexts: any[] =
    data?.content?.texts && yaml.load(data?.content?.texts);

  const getInfoBlocks = (item, index) => {
    const text: string = item.data.replace(/(?:\r\n|\r|\n)/g, '<br>');

    return (
      <li className={classes.infoWrapperStyle} key={index}>
        <div
          className={clsx(
            classes.labelStyle,
            isLightTheme && classes.labelLightStyle
          )}
        >
          {item.header}
        </div>
        <div
          className={clsx(
            classes.textStyle,
            isLightTheme && classes.textLightStyle
          )}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </li>
    );
  };

  return (
    <div className={classes.infoContainerStyle}>
      <div
        className={clsx(
          classes.headerStyle,
          isLightTheme && classes.headerLightStyle
        )}
      >
        {data?.content?.title}
      </div>
      <Scrollbars ref={scrollRef}>
        <ul>{infoTexts?.map(getInfoBlocks)}</ul>
      </Scrollbars>
    </div>
  );
};

export default RightInfoObjectData;
