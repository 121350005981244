import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Language from './Language';
import React from 'react';
import ThemeSwitch from './ThemeSwitch';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconHome } from '../assets/svg/ico_home.svg';
import { clearData } from '../common/app/actions';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import HeaderButtons from './HeaderButtons';
import { ReactComponent as IconLV } from '../assets/svg/interreg_lv.svg';
import { ReactComponent as IconLT } from '../assets/svg/interreg_lt.svg';
import { ReactComponent as IconEN } from '../assets/svg/interreg_en.svg';
import { Lng } from '../common/enums';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      headerContainerStyle: {
        position: 'relative',
        width: '100%',
        height: `${120 / C.PIXEL_SIZE}px`,
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'flex-start',
        justifyContent: 'space-between',
        paddingBottom: `${100 / C.PIXEL_SIZE}px`,
        borderBottom: `1px solid ${COLORS.white30}`,
        fontFamily: FONTS.montserrat,
      },
      homeIconWrapperStyle: {
        width: `${120 / C.PIXEL_SIZE}px`,
        height: `${120 / C.PIXEL_SIZE}px`,
        minWidth: `${120 / C.PIXEL_SIZE}px`,
        background: COLORS.appButtonsGray,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: `${120 / C.PIXEL_SIZE}px`,
        opacity: 0.3,
      },
      homeIconWrapperLightStyle: {
        pointerEvents: 'none',
        background: COLORS.textBlack30,
      },
      homeIconStyle: {
        width: `${42 / C.PIXEL_SIZE}px`,
      },
      leftSideStyle: {
        // width: '50%',
        display: 'flex',
        alignItems: 'center',
      },
      rightSideStyle: {
        // width: '50%',
        display: 'flex',
        alignItems: 'center',
      },
      activeHomeStyle: {
        opacity: 1,
        pointerEvents: 'auto',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
      logoStyle: {
        background: COLORS.white,
        width: `${690 / C.PIXEL_SIZE}px`, // '230px',
        height: `${270 / C.PIXEL_SIZE}px`, // '90px',
      },
    })
);

const LNG_ICONS = {
  [Lng.LV]: IconLV,
  [Lng.LT]: IconLT,
  [Lng.EN]: IconEN,
};

const Header = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const lng = useSelector((state: RootStateOrAny) => state.app.lng);
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  const isRoot = pathname === ROUTES.ROOT;

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(clearData(C.STORE_INFO_DATA));
    navigate(ROUTES.ROOT);
  };

  const props = {
    ...(!isRoot ? { onClick } : {}),
  };

  const Icon = LNG_ICONS[lng];

  return (
    <div className={classes.headerContainerStyle}>
      <div className={classes.leftSideStyle}>
        <div
          className={clsx(
            classes.homeIconWrapperStyle,
            isLightTheme && classes.homeIconWrapperLightStyle,
            !isRoot && classes.activeHomeStyle
          )}
          {...props}
        >
          <IconHome className={classes.homeIconStyle} />
        </div>
        <HeaderButtons />
        <Language />
        <ThemeSwitch />
      </div>

      <div className={classes.rightSideStyle}>
        <Icon className={classes.logoStyle} />
      </div>
    </div>
  );
};

export default Header;
