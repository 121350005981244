import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconDark } from '../assets/svg/ico_moon.svg';
import { ReactComponent as IconLight } from '../assets/svg/ico_sun.svg';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useSwitch, UseSwitchProps } from '@mui/base/SwitchUnstyled';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { setTheme } from '../common/app/actions';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      themeSwitchContainerStyle: {
        position: 'relative',
        // width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      switchWrapperStyle: {
        fontSize: 0,
        position: 'relative',
        display: 'inline-block',
        width: `${106 / C.PIXEL_SIZE}px`,
        height: `${56 / C.PIXEL_SIZE}px`,
        background: COLORS.themeSwitchGray,
        borderRadius: `${28 / C.PIXEL_SIZE}px`,
        cursor: 'pointer',
        margin: `0 ${35 / C.PIXEL_SIZE}px`,
      },
      switchWrapperLightStyle: {
        background: COLORS.textBlack30,
      },
      switchInputStyle: {
        cursor: 'inherit',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 0,
        zIndex: 1,
        margin: 0,
      },
      switchThumbStyle: {
        display: 'block',
        width: `${44 / C.PIXEL_SIZE}px`,
        height: `${44 / C.PIXEL_SIZE}px`,
        top: `${6 / C.PIXEL_SIZE}px`,
        left: `${6 / C.PIXEL_SIZE}px`,
        borderRadius: '50%',
        backgroundColor: COLORS.white,
        position: 'relative',
        transition: 'all 200ms ease',
      },
      switchThumbLightStyle: {
        left: `${56 / C.PIXEL_SIZE}px`,
      },
      darkIconStyle: {
        width: `${42 / C.PIXEL_SIZE}px`,
        fill: COLORS.textBlack30,
      },
      activeDarkIconStyle: {
        fill: COLORS.white,
      },
      lightIconStyle: {
        width: `${52 / C.PIXEL_SIZE}px`,
        fill: COLORS.themeSwitchGray,
      },
      activeLightIconStyle: {
        fill: COLORS.themeSwitchGray,
      },
    })
);

function BasicSwitch(props: UseSwitchProps) {
  const { getInputProps, checked } = useSwitch(props);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTheme(checked));
  }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={clsx(
        classes.switchWrapperStyle,
        checked && classes.switchWrapperLightStyle
      )}
    >
      <div
        className={clsx(
          classes.switchThumbStyle,
          checked && classes.switchThumbLightStyle
        )}
      />
      <input {...getInputProps()} className={classes.switchInputStyle} />
    </div>
  );
}

const ThemeSwitch = (): JSX.Element => {
  const classes = useStyles();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  return (
    <div className={classes.themeSwitchContainerStyle}>
      <IconDark
        className={clsx(
          classes.darkIconStyle,
          !isLightTheme ? classes.activeDarkIconStyle : ''
        )}
      />
      <BasicSwitch />
      <IconLight
        className={clsx(
          classes.lightIconStyle,
          isLightTheme ? classes.activeLightIconStyle : ''
        )}
      />
    </div>
  );
};

export default ThemeSwitch;
