import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import LeftPanel from './LeftPanel';
import MapContainer from './MapContainer';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useLogo } from '../common/hooks/useLogo';
import RightInfoObject from './RightInfoObject';
import { useCityData } from '../common/hooks/useCityData';
import LeftPanelWrapper from './LeftPanelWrapper';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      rightStyle: {
        position: 'relative',
        // minWidth: `${C.MAP_MAX_WIDTH}px`,
        width: '100%',
        height: '100%',
        borderRadius: `${8 / C.PIXEL_SIZE}px`,
        overflow: 'hidden',
      },
      logoStyle: {
        position: 'absolute',
        left: 0,
        top: `${4 / C.PIXEL_SIZE}px`,
        width: `${215 / C.PIXEL_SIZE}px`,
        height: `${240 / C.PIXEL_SIZE}px`,
        // background: COLORS.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
          width: '100%',
          height: '100%',
        },
      },
      infoPanelStyle: {
        position: 'absolute',
        width: `${760 / C.PIXEL_SIZE}px`,
        height: '100%',
        top: 0,
        right: 0,
        borderRadius: `${8 / C.PIXEL_SIZE}px`,
        background: COLORS.aboutBackgroundDark,
        border: `1px solid ${COLORS.aboutBorderDark}`,
        boxShadow: `0px ${30 / C.PIXEL_SIZE}px ${
          60 / C.PIXEL_SIZE
        }px rgba(0,0,0,.10)`,
        boxSizing: 'border-box',
      },
      infoPanelLightStyle: {
        background: COLORS.white,
        border: `1px solid ${COLORS.aboutBorderLight}`,
      },
    })
);

const City = (): JSX.Element => {
  const classes = useStyles();
  const { cityId } = useParams();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );
  const infoData = useSelector((state: RootStateOrAny) => state.app.infoData);
  const LogoIcon = useLogo(cityId);
  const { cityData, sectionData } = useCityData();

  if (!cityData || !sectionData) {
    return null;
  }

  const headerText = sectionData.content.title;

  return (
    <LeftPanelWrapper>
      <LeftPanel headerText={headerText} />
      <div className={classes.rightStyle}>
        <MapContainer />
        <div className={classes.logoStyle}>
          <LogoIcon />
        </div>
        {infoData && (
          <div
            className={clsx(
              classes.infoPanelStyle,
              isLightTheme && classes.infoPanelLightStyle
            )}
          >
            <RightInfoObject />
          </div>
        )}
      </div>
    </LeftPanelWrapper>
  );
};

export default City;
