import { ReactComponent as IconJekabpils } from '../../assets/svg/gerbonis_jekabpils.svg';
import { ReactComponent as IconLudza } from '../../assets/svg/gerbonis_ludza.svg';
import { ReactComponent as IconRokiski } from '../../assets/svg/gerbonis_rokiskis.svg';

const LOGO = {
  jekabpils: IconJekabpils,
  ludza: IconLudza,
  rokiski: IconRokiski,
};

export const useLogo = (cityId: string) => {
  const Icon = LOGO[cityId];

  return Icon;
};
