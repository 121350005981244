import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as CloseIcon } from '../assets/svg/ico_close.svg';
import diplomaBg from '../assets/img/diploma.jpg';
import { getPath } from '../common/utils/helpers';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useAppData } from '../common/hooks/useAppData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import QRCode from 'react-qr-code';
import queryString from 'query-string';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      diplomaContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: `${6 / C.PIXEL_SIZE}px`,
        background: COLORS.aboutBackgroundDark,
        border: `1px solid ${COLORS.aboutBorderDark}`,
        boxShadow: `0px ${30 / C.PIXEL_SIZE}px ${
          60 / C.PIXEL_SIZE
        }px rgba(0,0,0,.10)`,
        boxSizing: 'border-box',
        padding: `${80 / C.PIXEL_SIZE}px`,
        fontFamily: FONTS.montserrat,
      },
      diplomaContainerLightStyle: {
        background: COLORS.white,
        border: `1px solid ${COLORS.aboutBorderLight}`,
      },
      contentContainerStyle: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      imageContainerStyle: {
        position: 'relative',
        width: `${1290 / C.PIXEL_SIZE}px`,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      nameStyle: {
        position: 'absolute',
        fontFamily: FONTS.cinzel,
        lineHeight: `${126 / C.PIXEL_SIZE}px`,
        fontSize: `${120 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.regular,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: `${600 / C.PIXEL_SIZE}px`,
        whiteSpace: 'normal',
        textAlign: 'center',
      },
      diplomaStyle: {
        position: 'absolute',
        height: '100%',
      },
      codeContainerStyle: {
        width: `${1300 / C.PIXEL_SIZE}px`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      codeWrapperStyle: {
        width: `${800 / C.PIXEL_SIZE}px`,
        height: `${800 / C.PIXEL_SIZE}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: `${8 / C.PIXEL_SIZE}px`,
        background: COLORS.white,
      },
      downloadTextStyle: {
        lineHeight: `${72 / C.PIXEL_SIZE}px`,
        fontSize: `${64 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
        marginTop: `${120 / C.PIXEL_SIZE}px`,
      },
      // headerStyle: {
      //   width: '100%',
      //   textAlign: 'center',
      //   fontSize: '64px',
      //   fontWeight: FONT_WEIGHT.bold,
      //   color: COLORS.white,
      //   marginBottom: '60px',
      //   textTransform: 'uppercase',
      // },
      // headerLightStyle: {
      //   color: COLORS.aboutBackgroundDark,
      // },
      // introStyle: {
      //   lineHeight: '46px',
      //   fontSize: '32px',
      //   fontWeight: FONT_WEIGHT.medium,
      //   color: COLORS.white,
      //   marginBottom: '60px',
      //   textAlign: 'justify',
      //   textJustify: 'inter-word',
      // },
      // introLightStyle: {
      //   color: COLORS.aboutBackgroundDark,
      // },
      // textStyle: {
      //   lineHeight: '46px',
      //   fontSize: '32px',
      //   fontWeight: FONT_WEIGHT.regular,
      //   color: COLORS.white,
      //   marginBottom: '20px',
      //   textAlign: 'justify',
      //   textJustify: 'inter-word',
      // },
      // textLightStyle: {
      //   color: COLORS.aboutBackgroundDark,
      // },
      closeStyle: {
        position: 'absolute',
        top: `${180 / C.PIXEL_SIZE}px`,
        right: `${180 / C.PIXEL_SIZE}px`,
        width: `${120 / C.PIXEL_SIZE}px`,
        height: `${120 / C.PIXEL_SIZE}px`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
      },
    })
);

const Diploma = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  const currentAppData = useAppData();
  const { cityId } = useParams();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  if (!currentAppData) {
    return null;
  }

  const citiesData = currentAppData?.cities;
  const currentCityData = citiesData?.[cityId];
  const homeData = currentAppData?.home;
  const gameTextsData = homeData?.pages?.find(
    (item) => item.content.uid === 'game-texts'
  );
  // const sanitizedText: string = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

  // const hasImages = Boolean(currentAboutData?.images?.length);

  // const getImages = (imageData, index) => {
  //   const url = imageData.url;
  //   const imgUrl: string = `${urlPrefix}${url}`;
  //   return <img src={imgUrl} alt="" className={classes.imgStyle} key={index} />;
  // };

  const onClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    navigate(ROUTES.ROOT);
  };

  const QRCodeLink = 'https://www.jpb.lv/';

  const searchParams = queryString.parse(search);
  const name = decodeURI(searchParams?.name as string);

  return (
    <>
      <div
        className={clsx(
          classes.diplomaContainerStyle,
          isLightTheme && classes.diplomaContainerLightStyle
        )}
      >
        <div className={classes.contentContainerStyle}>
          <div className={classes.imageContainerStyle}>
            <img src={diplomaBg} alt="" className={classes.diplomaStyle} />
            <div className={classes.nameStyle}>{name}</div>
          </div>
          <div className={classes.codeContainerStyle}>
            <div className={classes.codeWrapperStyle}>
              <QRCode
                size={460}
                value={QRCodeLink}
                fgColor={'black'}
                bgColor={'white'}
              />
            </div>
            <div className={classes.downloadTextStyle}>
              {gameTextsData?.content?.diploma_download}
            </div>
          </div>
          {/* <div
            className={clsx(
              classes.introStyle,
              isLightTheme && classes.introLightStyle
            )}
            dangerouslySetInnerHTML={{ __html: sanitizedIntro }}
          /> */}
        </div>
        {/* {hasImages && (
          <div className={classes.imgContainerStyle}>
            {currentAboutData?.images.map(getImages)}
          </div>
        )} */}
      </div>
      <div
        className={classes.closeStyle}
        onClick={onClose}
        style={{ background: currentCityData.content.color }}
      >
        <CloseIcon className={classes.closeIconStyle} />
      </div>
    </>
  );
};

export default Diploma;
