import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Button from './Button';
import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { getText, getPath } from '../common/utils/helpers';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useLogo } from '../common/hooks/useLogo';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      cityContainerStyle: {
        position: 'relative',
        width: `${680 / C.PIXEL_SIZE}px`,
        maxWidth: `${680 / C.PIXEL_SIZE}px`,
        display: 'flex',
        flexDirection: 'column',
        marginRight: `${40 / C.PIXEL_SIZE}px`,
        '&:last-child': {
          marginRight: 0,
        },
      },
      imgWrapperStyle: {
        width: '100%',
        height: `${1060 / C.PIXEL_SIZE}px`,
        // flex: 1,
        position: 'relative',
        borderRadius: `${6 / C.PIXEL_SIZE}px`,
        overflow: 'hidden',
        marginBottom: `${20 / C.PIXEL_SIZE}px`,
      },
      imageStyle: {
        position: 'absolute',
        width: '100%',
        objectFit: 'cover',
      },
      logoStyle: {
        width: `${135 / C.PIXEL_SIZE}px`,
        height: `${160 / C.PIXEL_SIZE}px`,
        margin: `${40 / C.PIXEL_SIZE}px`,
        position: 'absolute',
      },
      labelStyle: {
        position: 'absolute',
        fontFamily: FONTS.montserrat,
        fontSize: `${48 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
        left: `${40 / C.PIXEL_SIZE}px`,
        top: `${960 / C.PIXEL_SIZE}px`,
        textTransform: 'uppercase',
      },
    })
);

const HomeCity = ({ data }): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [imageData] = data.images;
  const imgUrl: string = `${urlPrefix}${imageData.url}`;

  const cityId = data.content.uid;

  const LogoIcon = useLogo(cityId);

  const isSubPages = Boolean(data?.pages?.length);
  const cityLabel = data.content.city;

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const uri = event.currentTarget.getAttribute('data-id');
    navigate({ pathname: `${ROUTES.CITY}/${uri}` });
  };

  const getButtons = (buttonData, index) => {
    const { title, uri } = buttonData?.content;

    if (!title) {
      return null;
    }

    return (
      <Button
        color={data.content.color}
        label={title}
        key={index}
        fullWidth
        dataId={uri}
        onClick={onClick}
      />
    );
  };

  const exploreCityData = data?.pages?.find(
    (item) => item.content.uid === 'iepazisti-pilsetu'
  );
  const isGameCity = Boolean(exploreCityData?.pages?.length);

  // console.log('>>>>', data?.pages);

  const onGameClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    navigate({ pathname: `${ROUTES.CITY}/${cityId}${ROUTES.GAME}` });
  };

  return (
    <div className={classes.cityContainerStyle}>
      <div className={classes.imgWrapperStyle}>
        <img className={classes.imageStyle} src={imgUrl} alt="" />
        <LogoIcon className={classes.logoStyle} />
        <div className={classes.labelStyle}>{cityLabel}</div>
      </div>
      {isSubPages && data?.pages.map(getButtons)}
      {isGameCity && (
        <Button
          color={data.content.color}
          label={'SPĒLE'}
          fullWidth
          onClick={onGameClick}
        />
      )}
    </div>
  );
};

export default HomeCity;
