import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Button from './Button';
import React from 'react';
import happyBg from '../assets/img/happy_people.png';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { storeData } from '../common/app/actions';
import { useAppData } from '../common/hooks/useAppData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      gameSuccessContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: FONTS.montserrat,
      },
      bgStyle: {
        position: 'absolute',
        // width: 'auto',
        height: 'auto',
        bottom: 0,
        width: `${850 / C.PIXEL_SIZE}px`,
        marginBottom: `${200 / C.PIXEL_SIZE}px`,
        marginRight: `${80 / C.PIXEL_SIZE}px`,
      },
      btnsContainerStyle: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
      },
      buttonStyle: {
        width: `${720 / C.PIXEL_SIZE}px`,
        height: `${120 / C.PIXEL_SIZE}px`,
        margin: `0 ${60 / C.PIXEL_SIZE}px 0 0`,
        whiteSpace: 'nowrap',
        '&:last-child': {
          margin: 0,
        },
      },
      textContainerStyle: {
        position: 'absolute',
        top: `${240 / C.PIXEL_SIZE}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: COLORS.white,
      },
      headerStyle: {
        fontSize: `${84 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        marginBottom: `${40 / C.PIXEL_SIZE}px`,
      },
      textStyle: {
        fontSize: `${48 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        marginBottom: `${80 / C.PIXEL_SIZE}px`,
      },
      countStyle: {
        fontSize: `${84 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        letterSpacing: `${10 / C.PIXEL_SIZE}px`,
      },
      textContainerLightStyle: {
        color: COLORS.textBlack,
      },
    })
);

const GameSuccess = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAppData = useAppData();
  const { cityId } = useParams();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  if (!currentAppData) {
    return null;
  }

  const citiesData = currentAppData?.cities;
  const currentCityData = citiesData?.[cityId];
  const homeData = currentAppData?.home;
  const gameTextsData = homeData?.pages?.find(
    (item) => item.content.uid === 'game-texts'
  );

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const url = event.currentTarget.getAttribute('data-id');

    dispatch(storeData(null, C.STORE_INFO_DATA));
    dispatch(storeData(null, C.MAP_DATA));
    dispatch(storeData(null, C.EXPAND_DATA));

    navigate(url);
  };

  const BUTTONS = [
    {
      label: gameTextsData?.content?.button_diploma,
      url: `${ROUTES.CITY}/${cityId}${ROUTES.KEYBOARD}`,
    },
    {
      label: gameTextsData?.content?.button_again,
      url: `${ROUTES.CITY}/${cityId}${ROUTES.GAME}`,
    },
    {
      label: gameTextsData?.content?.button_home,
      url: ROUTES.ROOT,
    },
  ];

  const getButtons = ({ label, url }, index) => {
    return (
      <Button
        color={currentCityData.content.color}
        label={label}
        key={index}
        fullWidth
        dataId={url}
        onClick={onClick}
        className={classes.buttonStyle}
      />
    );
  };

  return (
    <div className={classes.gameSuccessContainerStyle}>
      <div
        className={clsx(
          classes.textContainerStyle,
          isLightTheme && classes.textContainerLightStyle
        )}
      >
        <div className={classes.headerStyle}>
          {gameTextsData?.content?.congrats}
        </div>
        <div className={classes.textStyle}>
          {gameTextsData?.content?.success_game}
        </div>
        <div className={classes.countStyle}>{'10/10'}</div>
      </div>
      <img src={happyBg} alt="" className={classes.bgStyle} />
      <div className={classes.btnsContainerStyle}>
        {BUTTONS.map(getButtons)}
      </div>
    </div>
  );
};

export default GameSuccess;
