import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import darkBg from '../assets/img/background_dark.jpg';
import lightBg from '../assets/img/background_light.jpg';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ThemeType } from '../common/enums';
import { fetchData } from '../common/app/actions';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

interface IProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      layoutContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: 'auto',
        minHeight: '100%',
        flex: 1,
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        // overflow: 'hidden',
      },
      bgStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // left: 0,
        // top: 0,
        objectFit: 'cover',
      },
      layoutContentStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: `${120 / C.PIXEL_SIZE}px`,
        boxSizing: 'border-box',
      },
      centerContainerStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '30vh',
        flex: 1,
        pointerEvents: 'none',
      },
    })
);

const Layout = ({ children }: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );
  const appData: any = useSelector(
    (state: RootStateOrAny) => state.app.appData
  );

  useEffect(() => {
    dispatch(fetchData(C.APP_DATA));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const bg = isLightTheme ? lightBg : darkBg;
  const themeColor = isLightTheme ? ThemeType.PRIMARY : ThemeType.SECONDARY;

  const getContent = () => {
    if (!appData) {
      return (
        <div className={classes.centerContainerStyle}>
          <CircularProgress color={themeColor} size={80 / C.PIXEL_SIZE} />
        </div>
      );
    }

    if (
      pathname.includes(`${ROUTES.ABOUT}/`) ||
      pathname.includes(`${ROUTES.DIPLOMA}`)
    ) {
      return children;
    }

    return (
      <>
        <Header />
        {children}
      </>
    );
  };

  return (
    <div className={classes.layoutContainerStyle}>
      <img src={bg} alt="" className={classes.bgStyle} />
      <div className={classes.layoutContentStyle}>{getContent()}</div>
    </div>
  );
};

export default Layout;
