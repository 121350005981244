import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import DragProvider from './DragProvider';
import LeftPanel from './LeftPanel';
import MapContainer from './MapContainer';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { storeGameData } from '../common/app/actions';
import { useAppData } from '../common/hooks/useAppData';
import { useGameData } from '../common/hooks/useGameData';
import { useLogo } from '../common/hooks/useLogo';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import LeftPanelWrapper from './LeftPanelWrapper';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      rightStyle: {
        position: 'relative',
        // minWidth: `${C.MAP_MAX_WIDTH}px`,
        width: '100%',
        height: '100%',
        borderRadius: `${8 / C.PIXEL_SIZE}px`,
        overflow: 'hidden',
      },
      logoStyle: {
        position: 'absolute',
        left: 0,
        top: `${4 / C.PIXEL_SIZE}px`,
        width: `${215 / C.PIXEL_SIZE}px`,
        height: `${240 / C.PIXEL_SIZE}px`,
        // background: COLORS.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
          width: '100%',
          height: '100%',
        },
      },
      infoPanelStyle: {
        position: 'absolute',
        width: `${760 / C.PIXEL_SIZE}px`,
        height: '100%',
        top: 0,
        right: 0,
        borderRadius: `${8 / C.PIXEL_SIZE}px`,
        background: COLORS.aboutBackgroundDark,
        border: `1px solid ${COLORS.aboutBorderDark}`,
        boxShadow: `0px ${30 / C.PIXEL_SIZE}px ${
          60 / C.PIXEL_SIZE
        }px rgba(0,0,0,.10)`,
        boxSizing: 'border-box',
      },
      infoPanelLightStyle: {
        background: COLORS.white,
        border: `1px solid ${COLORS.aboutBorderLight}`,
      },
    })
);

const Game = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { cityId } = useParams();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );
  const gameData = useSelector((state: RootStateOrAny) => state.app.gameData);
  const LogoIcon = useLogo(cityId);
  const currentAppData = useAppData();

  useGameData();

  useEffect(() => {
    if (gameData && gameData?.menuData?.length === 0) {
      dispatch(storeGameData(null));
      navigate(`${ROUTES.CITY}/${cityId}${ROUTES.SUCCESS}`);
    }
  }, [gameData?.menuData?.length]);

  const homeData = currentAppData?.home;
  const gameTextsData = homeData?.pages?.find(
    (item) => item.content.uid === 'game-texts'
  );

  if (!gameData || !currentAppData) {
    return null;
  }

  const headerText = gameTextsData.content.title;
  const subText = gameTextsData.content.instruction;

  return (
    <DragProvider>
      <LeftPanelWrapper>
        <LeftPanel headerText={headerText} subText={subText} isGame />
        <div className={classes.rightStyle}>
          <MapContainer isGame />
          <div className={classes.logoStyle}>
            <LogoIcon />
          </div>
        </div>
      </LeftPanelWrapper>
    </DragProvider>
  );
};

export default Game;
