import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React from 'react';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      leftPanelContainerStyle: {
        position: 'relative',
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        flex: 1,
        boxSizing: 'border-box',
        paddingTop: `${120 / C.PIXEL_SIZE}px`,
      },
      contentContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: `calc(100% - ${120 / C.PIXEL_SIZE}px)`,
        display: 'flex',
        flexDirection: 'row',
      },
    })
);

const LeftPanelWrapper = ({ children }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.leftPanelContainerStyle}>
      <div className={classes.contentContainerStyle}>{children}</div>
    </div>
  );
};

export default LeftPanelWrapper;
