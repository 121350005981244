import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React, { useState, useRef, useEffect, memo } from 'react';
import DropElement from './DropElement';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Marker as GoogleMarker,
  InfoWindow,
  Rectangle,
  OverlayView,
} from '@react-google-maps/api';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

interface IProps {
  markerData?: any;
  onClick?: (event: React.SyntheticEvent, markerData?: any) => void;
  nr?: number;
  color?: string;
  isGame?: boolean;
}

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      markerStyle: {
        position: 'absolute',
        width: `${20 / C.PIXEL_SIZE}px`,
        height: `${32 / C.PIXEL_SIZE}px`,
        fill: COLORS.appBlue,
        pointerEvents: 'all',
        cursor: 'pointer',
      },
      markerLabelStyle: {
        position: 'absolute',
        width: `${72 / C.PIXEL_SIZE}px`,
        left: `${4 / C.PIXEL_SIZE}px`,
        top: `${56}px`,
        textAlign: 'center',
      },
      markerPopStyle: {
        maxWidth: `${400 / C.PIXEL_SIZE}px`,
        width: '100%',
        position: 'relative',
        fontFamily: FONTS.montserrat,
        fontSize: `${24 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        lineHeight: `${32 / C.PIXEL_SIZE}px`,
        padding: `${8 / C.PIXEL_SIZE}px ${20 / C.PIXEL_SIZE}px`,
        textAlign: 'center',
        boxSizing: 'border-box',
      },
      dropPopStyle: {
        width: `${100 / C.PIXEL_SIZE}px`,
        height: `${100 / C.PIXEL_SIZE}px`,
        zIndex: 1000000,
      },
    })
);

const Marker = ({
  markerData,
  onClick,
  nr,
  color,
  isGame,
}: IProps): JSX.Element => {
  const gameData = useSelector((state: RootStateOrAny) => state.app.gameData);
  const currentMenuData = gameData?.menuData?.filter(
    (item) => item.content.uid === markerData?.content?.uid
  );

  const isGameMarkerSuccess = isGame && !currentMenuData.length;

  const classes = useStyles();
  const infoData = useSelector((state: RootStateOrAny) => state.app.infoData);

  const markerRef = useRef<any>();

  const { location: loc, title, uid } = markerData.content;

  const onMarkerClick = (event) => {
    onClick(event, markerData);
  };

  const lat = parseFloat(loc?.latitude);
  const lng = parseFloat(loc?.longitude);

  // const onLoad = (marker) => {
  //   // console.log('marker: ', marker);
  // };

  const isActive = infoData?.content.uid === uid;
  const fillColor = isActive || isGameMarkerSuccess ? COLORS.appGold : color;
  const markerPath =
    'M36,18A18,18,0,1,0,15.5,35.82V43.5a2.5,2.5,0,0,0,5,0V35.82A18,18,0,0,0,36,18Z'; // `M31,87V71.655a36,36,0,1,1,10,0V87a5,5,0,0,1-10,0Z`;

  const labelProps = isGame
    ? {}
    : {
        label: {
          text: `${nr}.`,
          fontFamily: FONTS.montserrat,
          fontWeight: 'bold',
          color: COLORS.white,
          fontSize: `${20}px`,
          className: classes.markerLabelStyle,
        },
      };

  const getInfoData = () => {
    if (isGame && !isGameMarkerSuccess) {
      const getPixelPositionOffset = (
        offsetWidth: number,
        offsetHeight: number
      ) => {
        return {
          x: -25,
          y: -48,
        };
      };

      // const DropArea = () => {
      //   return <div className={classes.dropPopStyle} />;
      // };

      return (
        <OverlayView
          position={{ lat, lng }}
          mapPaneName={OverlayView.FLOAT_PANE}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <DropElement uid={uid}>
            <div className={classes.dropPopStyle} />
          </DropElement>
        </OverlayView>
      );
    }

    if (isActive || isGameMarkerSuccess) {
      return (
        <InfoWindow>
          <div className={classes.markerPopStyle}>{title}</div>
        </InfoWindow>
      );
    }

    return null;
  };

  const clickProps = isGame ? {} : { onClick: onMarkerClick };

  return (
    <GoogleMarker
      ref={markerRef}
      position={{ lat, lng }}
      icon={{
        path: markerPath,
        fillColor,
        fillOpacity: 1,
        // origin: new google.maps.Point(0, 0),
        anchor: new (window as any).google.maps.Point(16, 40),
        strokeWeight: 1,
        // strokeColor: '#ffffff',
        // scale: 0.075,
      }}
      title={title}
      clickable
      // {...(isActive ? { animation: google.maps.Animation.BOUNCE } : {})}
      // onClick={onMarkerClick}
      // onLoad={onLoad}
      {...labelProps}
      {...clickProps}
      // onMouseOver={onMouseOver}
      // shape={shape}
    >
      {getInfoData()}
    </GoogleMarker>
  );
};

export default Marker;
