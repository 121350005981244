import { createTheme } from '@mui/material/styles';

export const COLORS = {
  appBlue: '#0B0B61',
  appLakeBlue: '#6496D9',
  appLakeBlueHover: '#B4D2FA',
  appButtonsGray: '#707070',
  appGray: '#555959',
  appGold: '#EAA200',
  appYellow: '#FFFF00',
  appPurple: '#5800B3',

  themeSwitchGray: '#5b5f61',
  aboutBorderDark: '#7E84A3',
  aboutBackgroundDark: '#131523',
  aboutBorderLight: '#CCCCCC',

  white: '#FFFFFF',
  white30: 'rgba(255,255,255, 0.3)',
  textBlack: 'rgba(19,21,35,1)', // #131523',
  textBlack60: 'rgba(19,21,35,0.6)',
  textBlack30: 'rgba(19,21,35,0.3)',

  darkBorder: '#7E84A3',
};

export const FONTS = {
  // myriadpro: 'Myriad Pro',
  // alegreya: 'Alegreya Sans',
  // garamond: 'EB Garamond',
  // roboto: 'Roboto',
  // source: 'SourceSerif',
  montserrat: 'Montserrat',
  cinzel: 'Cinzel',
};

export const FONT_WEIGHT = {
  // light: 300,
  regular: 400,
  medium: 500,
  // semiBold: 600,
  bold: 700,
};

// export const SIZES = {
//   maxSearchBlockWidth: 440,
// };

// const fontFamily: string = ['"Alegreya Sans"', 'Arial', 'sans'].join(',');

export const theme = createTheme({
  palette: {
    primary: { main: COLORS.appBlue },
    secondary: { main: COLORS.white },
    // background: {
    //   paper: COLORS.background,
    // },
    // type: 'dark',
  },
  typography: {
    // fontFamily,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    // fontWeightBold: 700,
    // h2: {
    //   fontSize: '50px',
    // },
    h3: {
      // verticalAlign: 'middle',
      // alignItems: 'center',
      // textAlign: 'center',
      // fontFamily: FONTS.garamond,
      // fontWeight: FONT_WEIGHT.medium,
      // fontSize: '20px',
      // color: COLORS.textBlack,
      // textTransform: 'uppercase',
    },
    // h1: {
    //   fontSize: '86px',
    //   color: COLORS.white,
    //   verticalAlign: 'middle',
    //   alignItems: 'center',
    //   textAlign: 'center',
    // },
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': [alegreyaSans, ebGaramond],
  //     },
  //   },
  // },
});
