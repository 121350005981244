import * as C from '../constants';
import { get, post } from '../utils/requests';
import { getPath } from '../utils/helpers';
import queryString from 'query-string';

export function storeData(payload: any, type: string) {
  return {
    payload,
    type,
  };
}

export function clearData(type: string, payload = null) {
  return {
    type,
    payload,
  };
}

export function setLanguage(payload) {
  return {
    type: C.SET_LANGUAGE,
    payload,
  };
}

export function storeError(payload: string) {
  return {
    type: C.STORE_ERROR,
    payload,
  };
}

export function closeError() {
  return {
    type: C.CLOSE_ERROR,
  };
}

export function onSuccessDrop(payload: string) {
  return {
    type: C.ON_SUCCESS_DROP,
    payload,
  };
}

export function setTheme(payload: boolean) {
  return {
    type: C.SET_THEME,
    payload,
  };
}

export function fetchData(type, params = {}) {
  const paramString = queryString.stringify(params);

  return (dispatch, getState) => {
    const urlPrefix = getPath();
    const url = `${urlPrefix}api?${paramString}`;

    return get(url)
      .then(({ data }) => {
        return dispatch(storeData(data, type));
      })
      .catch((error) => {
        // const errorData: IError = error.response && error.response.data;
        // return dispatch(throwGlobalError(errorData));
      });
  };
}

export function storeGameData(payload) {
  return {
    type: C.STORE_GAME_DATA,
    payload,
  };
}
