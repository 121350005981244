import { GAME_ITEMS } from '../constants';
import { useState, useLayoutEffect } from 'react';
import { useCityData } from './useCityData';
import { storeGameData } from '../app/actions';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

export const useGameData = () => {
  const { sectionData } = useCityData();
  const dispatch = useDispatch();
  const [state, setState] = useState(null);

  useLayoutEffect(() => {
    const pagesData = [];
    const allData = [...sectionData.pages];

    allData.forEach((item) => {
      item.pages.forEach((childItem) => {
        pagesData.push(childItem);
      });
    });

    const shufflePages = pagesData.sort((a, b) => 0.5 - Math.random());
    const pages = shufflePages.slice(0, GAME_ITEMS);

    const gameData = {
      menuData: pages,
      mapData: pages,
    };

    dispatch(storeGameData(gameData));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
