import * as C from './common/constants';
import * as ROUTES from './common/routes';
import About from './components/About';
import City from './components/City';
import DiplomaKeyboard from './components/DiplomaKeyboard';
import Diploma from './components/Diploma';
import Game from './components/Game';
import GameSuccess from './components/GameSuccess';
import Home from './components/Home';
import Layout from './components/Layout';
import NotFound from './components/NotFound';
import React from 'react';
import configureStore from './common/redux/configureStore';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './common/theme';
import {
  HashRouter as Router,
  Route,
  Routes,
  useParams,
  useLocation,
} from 'react-router-dom';

const store: Store = configureStore();

const CityOrGame = () => {
  const { pathname } = useLocation();

  if (pathname.includes(ROUTES.KEYBOARD)) {
    return <DiplomaKeyboard />;
  }

  if (pathname.includes(ROUTES.DIPLOMA)) {
    return <Diploma />;
  }

  if (pathname.includes(ROUTES.SUCCESS)) {
    return <GameSuccess />;
  }

  if (pathname.includes(ROUTES.GAME)) {
    return <Game />;
  }

  return <City />;
};

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Routes>
              <Route path={ROUTES.ROOT} element={<Home />} />
              <Route path={`${ROUTES.ABOUT}/:aboutId`} element={<About />} />
              <Route
                path={`${ROUTES.CITY}/:cityId/:sectionId/:objectId`}
                element={<City />}
              />

              <Route
                path={`${ROUTES.CITY}/:cityId/:sectionId`}
                element={<CityOrGame />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
