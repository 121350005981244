import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import CircularProgress from '@mui/material/CircularProgress';
import HomeCity from './HomeCity';
import HomeInfo from './HomeInfo';
import React, { useEffect, useState } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { fetchData } from '../common/app/actions';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useAppData } from '../common/hooks/useAppData';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      homeContainerStyle: {
        width: '100%',
        minHeight: '100%',
        // flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      leftStyle: {
        minHeight: `${940 / C.PIXEL_SIZE}px`,
        display: 'flex',
        alignItems: 'flex-end',
      },
      rightStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    })
);

const Home = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentAppData = useAppData();

  if (!currentAppData) {
    return null;
  }

  const citiesData = currentAppData?.cities;
  const homeData = currentAppData?.home;

  const getItems = (cityKey, index) => {
    const cityData = citiesData[cityKey];

    return <HomeCity data={cityData} key={index} />;
  };

  return (
    <div className={classes.homeContainerStyle}>
      <div className={classes.leftStyle}>
        <HomeInfo data={homeData} />
      </div>
      <div className={classes.rightStyle}>
        {Object.keys(citiesData).map(getItems)}
      </div>
    </div>
  );
};

export default Home;
