import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as CloseIcon } from '../assets/svg/ico_close.svg';
import { getPath } from '../common/utils/helpers';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useAppData } from '../common/hooks/useAppData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      aboutContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: `${6 / C.PIXEL_SIZE}px`,
        background: COLORS.aboutBackgroundDark,
        border: `1px solid ${COLORS.aboutBorderDark}`,
        boxShadow: `0px ${30 / C.PIXEL_SIZE}px ${
          60 / C.PIXEL_SIZE
        }px rgba(0,0,0,.10)`,
        boxSizing: 'border-box',
        padding: `${260 / C.PIXEL_SIZE}px ${240 / C.PIXEL_SIZE}px ${
          120 / C.PIXEL_SIZE
        }px ${240 / C.PIXEL_SIZE}px`,
        fontFamily: FONTS.montserrat,
      },
      aboutContainerLightStyle: {
        background: COLORS.white,
        border: `1px solid ${COLORS.aboutBorderLight}`,
      },
      textContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
      },
      headerStyle: {
        width: '100%',
        textAlign: 'center',
        fontSize: `${64 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        color: COLORS.white,
        marginBottom: `${60 / C.PIXEL_SIZE}px`,
        textTransform: 'uppercase',
      },
      headerLightStyle: {
        color: COLORS.aboutBackgroundDark,
      },
      introStyle: {
        lineHeight: `${46 / C.PIXEL_SIZE}px`,
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
        marginBottom: `${60 / C.PIXEL_SIZE}px`,
        textAlign: 'justify',
        textJustify: 'inter-word',
      },
      introLightStyle: {
        color: COLORS.aboutBackgroundDark,
      },
      textStyle: {
        lineHeight: `${46 / C.PIXEL_SIZE}px`,
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.regular,
        color: COLORS.white,
        marginBottom: `${20 / C.PIXEL_SIZE}px`,
        textAlign: 'justify',
        textJustify: 'inter-word',
      },
      textLightStyle: {
        color: COLORS.aboutBackgroundDark,
      },
      imgContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      imgWrapperStyle: {
        // width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      imgStyle: {
        maxHeight: `${250 / C.PIXEL_SIZE}px`,
      },
      closeStyle: {
        position: 'absolute',
        top: `${180 / C.PIXEL_SIZE}px`,
        right: `${180 / C.PIXEL_SIZE}px`,
        width: `${120 / C.PIXEL_SIZE}px`,
        height: `${120 / C.PIXEL_SIZE}px`,
        background: COLORS.appLakeBlue,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: COLORS.appLakeBlueHover,
            transition: '0.5s ease',
          },
        },
      },
    })
);

const About = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { aboutId } = useParams();
  const lng = useSelector((state: RootStateOrAny) => state.app.lng);
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );
  const currentAppData = useAppData();

  if (!currentAppData) {
    return null;
  }

  const currentAboutData = currentAppData.home.pages.find(
    (item) => item.content.uid === aboutId
  );

  const { header, intro, text } = currentAboutData?.content;
  const sanitizedIntro: string = intro.replace(/(?:\r\n|\r|\n)/g, '<br>');
  const sanitizedText: string = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

  console.log('>>>>', currentAboutData?.images);

  const hasImages = Boolean(currentAboutData?.images?.length);

  const getImages = (imageData, index) => {
    const url = imageData.url;
    const imgUrl: string = `${urlPrefix}${url}`;
    return <img src={imgUrl} alt="" className={classes.imgStyle} key={index} />;
  };

  const onClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    navigate(ROUTES.ROOT);
  };

  return (
    <>
      <div
        className={clsx(
          classes.aboutContainerStyle,
          isLightTheme && classes.aboutContainerLightStyle
        )}
      >
        <div className={classes.textContainerStyle}>
          <div
            className={clsx(
              classes.headerStyle,
              isLightTheme && classes.headerLightStyle
            )}
          >
            {header}
          </div>
          <div
            className={clsx(
              classes.introStyle,
              isLightTheme && classes.introLightStyle
            )}
            dangerouslySetInnerHTML={{ __html: sanitizedIntro }}
          />
          <div
            className={clsx(
              classes.textStyle,
              isLightTheme && classes.textLightStyle
            )}
            dangerouslySetInnerHTML={{ __html: sanitizedText }}
          />
        </div>
        {hasImages && (
          <div className={classes.imgContainerStyle}>
            <div className={classes.imgWrapperStyle}>
              {currentAboutData?.images.map(getImages)}
            </div>
          </div>
        )}
      </div>
      <div className={classes.closeStyle} onClick={onClose}>
        <CloseIcon className={classes.closeIconStyle} />
      </div>
    </>
  );
};

export default About;
