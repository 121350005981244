import * as ROUTES from '../common/routes';
import React from 'react';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      notFoundContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
    })
);

const NotFound = (): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.notFoundContainerStyle}></div>;
};

export default NotFound;
