import * as C from '../common/constants';
import MultiBackend, {
  DndProvider,
  TouchTransition,
  MouseTransition,
  usePreview,
  PreviewState,
  Preview,
} from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
// import { HTML5toTouch as HTML5toTouchSpecial } from 'rdndmb-html5-to-touch';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider as ReactDndProvider } from 'react-dnd';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';

import {
  deviceType,
  primaryInput,
  supportsPointerEvents,
  supportsTouchEvents,
  supportsPassiveEvents,
} from 'detect-it';

type DragContent = {
  type: string;
  color: string;
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      dragStyle: {
        width: `${760 / C.PIXEL_SIZE}px`,
        minHeight: `${120 / C.PIXEL_SIZE}px`,
        fontFamily: FONTS.montserrat,
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        lineHeight: `${42 / C.PIXEL_SIZE}px`,
        color: COLORS.white,
        boxSizing: 'border-box',
        padding: `${20 / C.PIXEL_SIZE}px ${80 / C.PIXEL_SIZE}px`,
        borderRadius: `${8 / C.PIXEL_SIZE}px`,
        background: COLORS.aboutBackgroundDark,
        border: `1px solid ${COLORS.aboutBorderDark}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    })
);

const DragProvider = ({ children }) => {
  // const legacyOptions = {
  //   enableTouchEvents: true,
  //   enableMouseEvents: true,
  //   ignoreContextMenu: true,
  //   preview: true,
  // };
  const classes = useStyles();

  const HTML5toTouchOptions = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
        preview: true,
        // skipDispatchOnTransition: true,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
        skipDispatchOnTransition: true,
      },
    ],
  };

  // const Block = ({
  //   row,
  //   text,
  //   item,
  //   style,
  // }: {
  //   row: number;
  //   text: string;
  //   item: DragContent;
  //   style: any;
  // }): JSX.Element => {
  //   return (
  //     <div
  //       style={{
  //         ...style,
  //         top: `${row * 60}px`,
  //         backgroundColor: item.color,
  //         width: '50px',
  //         height: '50px',
  //         whiteSpace: 'nowrap',
  //       }}
  //     >
  //       Generated {text}
  //     </div>
  //   );
  // };

  // const ComponentPreview = ({ text }: { text: string }): JSX.Element => {
  //   return (
  //     <Preview
  //       generator={({
  //         item,
  //         style,
  //       }: PreviewState<DragContent>): JSX.Element => {
  //         return (
  //           <Block
  //             row={2}
  //             text={`${text} with Component`}
  //             item={item}
  //             style={style}
  //           />
  //         );
  //       }}
  //     />
  //   );
  // };

  // const isTouch =
  //   'ontouchstart' in window ||
  //   (navigator as any)?.maxTouchPoints > 0 ||
  //   (navigator as any)?.msMaxTouchPoints > 0 ||
  //   window.matchMedia('(pointer: coarse)').matches ||
  //   window.ontouchstart !== undefined;

  // if (isTouch) {
  //   return (
  //     <ReactDndProvider backend={TouchBackend} options={legacyOptions}>
  //       {children}
  //     </ReactDndProvider>
  //   );
  // }

  const MyPreview = () => {
    const { display, itemType, item, style } = usePreview();

    if (!display) {
      return null;
    }

    return (
      <div className={classes.dragStyle} style={style}>
        {item?.title}
      </div>
    );
  };

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouchOptions}>
      {children}
      <MyPreview />
    </DndProvider>

    // <ReactDndProvider backend={HTML5Backend} options={legacyOptions}>
    //   {children}
    // </ReactDndProvider>

    // <Provider options={HTML5toTouch}>{children}</Provider>
  );
};

export default DragProvider;
