import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

function getConfig(config: AxiosRequestConfig = {}): AxiosRequestConfig {
  return {
    ...config,
    withCredentials: true,
    headers: {
      ...config.headers,
      // 'Cache-Control': 'no-cache',
      Accept: 'application/json',
    },
  };
}

const handleError = (json: any) => {
  if (json.error) {
    return Promise.reject(json.error.errorCode);
  }
  return json;
};

function axiosRequest<T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<any>> {
  return axios.request(config);
}

function request<T>(
  config: AxiosRequestConfig,
  returnResponse: boolean = false
): Promise<T> {
  return axiosRequest(config)
    .then(
      (response: AxiosResponse): T => {
        return returnResponse ? response : response.data;
      }
    )
    .then(handleError);
}

export function get<T = any>(url: string, params?): Promise<T> {
  return request<T>(getConfig({ method: 'GET', url, ...params }), true);
}

// export function remove<T = any>(
//   url: string,
//   returnResponse: boolean = false
// ): Promise<T> {
//   return request<T>(getConfig({ method: 'DELETE', url }), returnResponse);
// }

export function post<T = any, D = any>(
  url: string,
  data: D,
  params?,
  returnResponse: boolean = false
): Promise<T> {
  return request<T>(
    getConfig({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url,
      data,
      ...params,
    }),
    returnResponse
  );
}

// export function put<T = any>(
//   url: string,
//   data: any,
//   params?,
//   returnResponse: boolean = false
// ): Promise<T> {
//   return request<T>(
//     getConfig({
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       url,
//       data,
//       ...params,
//     }),
//     returnResponse
//   );
// }
