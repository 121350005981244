import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Button from './Button';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      homeInfoContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: FONTS.montserrat,
        marginRight: `${80 / C.PIXEL_SIZE}px`,
      },
      textContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: `${120 / C.PIXEL_SIZE + 2}px`,
      },
      gameStyle: {
        fontSize: `${48 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
        marginBottom: `${65 / C.PIXEL_SIZE}px`,
      },
      nameStyle: {
        fontSize: `${120 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        color: COLORS.white,
        marginBottom: `${55 / C.PIXEL_SIZE}px`,
      },
      citiesStyle: {
        fontSize: `${48 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: 'rgba(255,255,255,0.6)',
        textTransform: 'uppercase',
      },
      buttonContainerStyle: {
        width: `${1150 / C.PIXEL_SIZE}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      textLightStyle: {
        color: COLORS.textBlack,
      },
      citiesLightStyle: {
        color: COLORS.textBlack60,
      },
    })
);

const HomeInfo = ({ data }): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  const { cities, game, name } = data?.content;

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const uid = event.currentTarget.getAttribute('data-id');
    navigate({ pathname: `${ROUTES.ABOUT}/${uid}` });
  };

  const getButtons = (buttonData, index) => {
    const { color, header, uid } = buttonData?.content;

    return (
      <Button
        color={color}
        label={header}
        key={index}
        onClick={onClick}
        dataId={uid}
      />
    );
  };

  const buttonsData = data?.pages.filter(
    (item) => item?.content?.template === 'pages/about'
  );

  return (
    <div className={classes.homeInfoContainerStyle}>
      <div className={classes.textContainerStyle}>
        <div
          className={clsx(
            classes.gameStyle,
            isLightTheme && classes.textLightStyle
          )}
        >
          {game}
        </div>
        <div
          className={clsx(
            classes.nameStyle,
            isLightTheme && classes.textLightStyle
          )}
        >
          {name}
        </div>
        <div
          className={clsx(
            classes.citiesStyle,
            isLightTheme && classes.citiesLightStyle
          )}
        >
          {cities}
        </div>
      </div>
      <div className={classes.buttonContainerStyle}>
        {buttonsData?.map(getButtons)}
      </div>
    </div>
  );
};

export default HomeInfo;
