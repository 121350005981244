import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React, { useEffect, CSSProperties, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { storeData, onSuccessDrop } from '../common/app/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useDrag, useDragLayer, XYCoord } from 'react-dnd';
// import { usePreview } from 'react-dnd-preview';
import MultiBackend, {
  DndProvider,
  TouchTransition,
  MouseTransition,
  usePreview,
  PreviewState,
  Preview,
  DragPreviewImage,
} from 'react-dnd-multi-backend';

interface DropResult {
  uid: string;
}

const DragElement = ({ uid, title, children }) => {
  const dispatch = useDispatch();

  const thisRef = useRef(null);

  // useEffect(() => {
  //   preview(getEmptyImage(), { captureDraggingState: true });
  // }, []);
  const previewOptions = {
    offsetX: 0,
    offsetY: 0,
  };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: uid,
      item: { uid, title },
      previewOptions,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResult>();
        const didDrop = monitor.didDrop();

        console.log('>>>>>END', uid, dropResult, didDrop); // item, dropResult, didDrop);
        if (item && dropResult) {
          console.log(`You dropped ${item.uid} into ${dropResult.uid}!`);
          dispatch(onSuccessDrop(item.uid));
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        position: monitor.getClientOffset(),
      }),
    }),
    [uid]
  );

  // const { itemType, isDragging, item, initialOffset, currentOffset } =
  //   useDragLayer((monitor) => ({
  //     // item: monitor.getItem(),
  //     // itemType: monitor.getItemType(),
  //     type: uid,
  //     item: { uid },
  //     // end: (item, monitor) => {
  //     //   const dropResult = monitor.getDropResult();

  //     //   if (item && dropResult) {
  //     //     console.log(`You dropped ${item.uid} into ${dropResult.uid}!`);
  //     //     dispatch(onSuccessDrop(item.uid));
  //     //   }
  //     // },
  //     itemType: monitor.getItemType(),
  //     initialOffset: monitor.getInitialSourceClientOffset(),
  //     currentOffset: monitor.getSourceClientOffset(),
  //     isDragging: monitor.isDragging(),
  //   }));

  const opacity = isDragging ? 0.4 : 1;

  const callbackRef = useCallback(
    (node) => {
      drag(node);
      preview(node, previewOptions);
    },
    [drag, preview]
  );

  const MyPreview = () => {
    const { display, itemType, item, style, ref } = usePreview();

    if (!display) {
      return null;
    }

    console.log('>>>>', itemType);

    return (
      <div
        style={{
          ...style,
          width: '200px',
          height: '50px',
          background: 'red',
          zIndex: 1000,
          // left: '700px',
          // top: '500px',
        }}
        ref={ref}
      >
        {title}
      </div>
    );
  };

  const style = {
    opacity: isDragging ? 0.5 : 1,
    // touchAction: 'none',
    // pointerEvents: 'none' as any,
    // position: 'absolute !important' as any,
  };

  // const elem: any = document.elementFromPoint(2, 2);
  // elem.style.color = 'red';

  const generatePreview = (type, item, style) => {
    return <div style={style}>Generated</div>;
  };

  return (
    <div
      ref={drag}
      // role="Box"
      // style={{
      //   opacity,
      //   // top: 0,
      //   // left: 0,
      //   // zIndex: 999,
      //   // position: 'relative',
      //   // transform: `translate(${position?.x}px, ${position?.y}px)`,
      // }}
      // data-testid={`box-${header}`}
      // style={getStyles(left, top, isDragging)}
      // style={{ pointerEvents: 'auto' }}
    >
      {children}
    </div>
  );
};

export default DragElement;

//       {/* <div ref={callbackRef} style={{ ...style }}> */}
