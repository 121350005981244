import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import LeftPanelObjectMenu from './LeftPanelObjectMenu';
import LeftPanelObjectMenuItem from './LeftPanelObjectMenuItem';
import React, { useRef } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Scrollbars } from 'react-custom-scrollbars';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { storeData } from '../common/app/actions';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

interface IProps {
  data: any;
  color: any;
  isGame?: boolean;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      menuItemsContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        maxWidth: `${760 / C.PIXEL_SIZE}px`,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: FONTS.montserrat,
        overflowY: 'scroll',
      },
    })
);

const LeftPanelObjectMenuItems = ({
  data,
  color,
  isGame,
}: IProps): JSX.Element => {
  const classes = useStyles({ color });

  const scrollRef = useRef<any>();

  const getItems = (item, index) => {
    const hasSubDir = Boolean(item.pages.length);

    if (hasSubDir) {
      return <LeftPanelObjectMenu data={item} key={index} color={color} />;
    }

    return (
      <LeftPanelObjectMenuItem
        data={item}
        key={index}
        nr={index + 1}
        color={color}
        isGame={isGame}
      />
    );
  };

  const onScrollStop = () => {
    // console.log('>>>ScrollStop', scrollRef);
  };

  return (
    <Scrollbars
      ref={scrollRef}
      // className={styles.scroll}
      style={{ height: 1450 }}
      onScrollStop={onScrollStop}
    >
      <div className={classes.menuItemsContainerStyle}>
        {data.map(getItems)}
      </div>
    </Scrollbars>
  );
};

export default LeftPanelObjectMenuItems;

/*
  private handleScrollStop = () => {
    const currentTop: number = this.scroll.getScrollTop();

    if (currentTop >= this.state.height - DEFAULT_SCROLL_HEIGHT - 10) {
      this.setState({ isMenuDownArrow: false });
    } else {
      this.setState({ isMenuDownArrow: true });
    }

    if (currentTop > 0) {
      this.setState({ isMenuUpArrow: true });
    } else {
      this.setState({ isMenuUpArrow: false });
    }
  };

  private scrollDown = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.scroll) {
      return;
    }

    const currentTop: number = this.scroll.getScrollTop();
    const newTop: number = currentTop + 200;
    this.scrollTo(newTop);
  };

  private scrollUp = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.scroll) {
      return;
    }

    const currentTop: number = this.scroll.getScrollTop();
    const newTop: number = currentTop - 200;
    this.scrollTo(newTop);
  };

  private scrollTo = (top) => {
    this.scroll.view.scroll({
      top,
      left: 0,
      behavior: 'smooth',
    });
  };
}
*/
