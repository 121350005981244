import { Lng } from '../enums';

export const getText = (content, type, lng) => {
  const text = content?.[`${type}_${lng}`];

  if (text) {
    return text;
  }

  const lvText = content?.[`${type}_${Lng.LV}`];

  if (lvText) {
    return lvText;
  }

  return '';
};

export const getPath = () => {
  return process.env.NODE_ENV !== 'production'
    ? 'http://localhost:8080/'
    : './admin/';
};

export const scrollParentToChildPos = (
  child: HTMLElement | null,
  childDepth: number = 1,
  behavior: ScrollBehavior = 'smooth'
) => {
  if (!child || !child.parentElement) {
    return;
  }
  let parent: HTMLElement | null = child;
  for (let i = 0; i < childDepth; i++) {
    parent = parent.parentElement;
    if (!parent) {
      return;
    }
  }
  const finalPosition: number =
    child.offsetLeft - parent.clientWidth * 0.5 + child.clientWidth * 0.5;

  if (parent.scrollTo) {
    parent.scrollTo({
      left: finalPosition,
      behavior,
    });
  } else {
    parent.scrollLeft = finalPosition;
  }
};

export const sortData = (pages) => {
  if (!!pages?.length) {
    return pages.sort((a, b) => {
      var da = new Date(a.content?.date || 0).getTime();
      var db = new Date(b.content?.date || 0).getTime();

      return db - da;
    });
  }

  return pages;
};
