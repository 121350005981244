import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import DragElement from './DragElement';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { storeData } from '../common/app/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

interface IProps {
  data: any;
  nr: any;
  color: any;
  isGame?: boolean;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      itemWrapperStyle: {
        position: 'relative',
        width: '100%',
        minHeight: `${120 / C.PIXEL_SIZE}px`,
        background: 'none',
        fontFamily: FONTS.montserrat,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: (props: any) => props.color,
            transition: '0.5s ease',
          },
        },
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        lineHeight: `${42 / C.PIXEL_SIZE}px`,
        color: COLORS.white,
        boxSizing: 'border-box',
        padding: `${20 / C.PIXEL_SIZE}px ${80 / C.PIXEL_SIZE}px`,
        display: 'flex',
        alignItems: 'center',
      },
      itemWrapperLightStyle: {
        color: COLORS.aboutBackgroundDark,
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: (props: any) => props.color,
            color: COLORS.white,
            transition: '0.5s ease',
          },
        },
      },
      lineStyle: {
        position: 'absolute',
        width: `${180 / C.PIXEL_SIZE}px`,
        height: `${2 / C.PIXEL_SIZE}px`,
        left: `${80 / C.PIXEL_SIZE}px`,
        bottom: 0,
        background: (props: any) => props.color,
      },
    })
);

const LeftPanelObjectMenuItem = ({
  data,
  nr,
  color,
  isGame,
}: IProps): JSX.Element => {
  const classes = useStyles({ color });
  const dispatch = useDispatch();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  const { title, uid } = data?.content;
  const label = isGame ? title : `${nr}. ${title}`;

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(storeData(data, C.STORE_INFO_DATA));
  };

  const clickProps = isGame ? {} : { onClick };

  const getContent = () => {
    return (
      <div
        className={clsx(
          classes.itemWrapperStyle,
          isLightTheme && classes.itemWrapperLightStyle
        )}
        {...clickProps}
      >
        {label}
        <div className={classes.lineStyle} />
      </div>
    );
  };

  if (isGame) {
    return (
      <DragElement uid={uid} title={title}>
        {getContent()}
      </DragElement>
    );
  }

  return getContent();
};

export default LeftPanelObjectMenuItem;
