import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Button from './Button';
import React from 'react';
import { ESections } from '../common/enums';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { storeData } from '../common/app/actions';
import { useAppData } from '../common/hooks/useAppData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      headerButtonsContainerStyle: {
        position: 'relative',
        height: `${120 / C.PIXEL_SIZE}px`,
        display: 'flex',
      },
      buttonStyle: {
        margin: `0 ${100 / C.PIXEL_SIZE}px 0 0`,
        padding: `0 ${70 / C.PIXEL_SIZE}px`,
        whiteSpace: 'nowrap',
      },
    })
);

const HeaderButtons = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentAppData = useAppData();
  const { pathname } = useLocation();

  if (!currentAppData) {
    return null;
  }

  const [, , cityId, sectionId] = pathname.split('/');

  if (!cityId && !sectionId) {
    return null;
  }

  if (sectionId === ESections.SUCCESS) {
    return null;
  }

  const homeData = currentAppData?.home;
  const citiesData = currentAppData?.cities;
  const currentCityData = citiesData?.[cityId];

  const getButtonsIDs = () => {
    const sections = [];

    const hasHistory = currentCityData?.pages?.find(
      (item) => item.content.uid === ESections.HISTORY
    );

    const hasExplore = currentCityData?.pages?.find(
      (item) => item.content.uid === ESections.EXPLORE
    );

    if (sectionId === ESections.GAME) {
      if (hasHistory) {
        sections.push(ESections.HISTORY);
      }

      if (hasExplore) {
        sections.push(ESections.EXPLORE);
      }

      return sections;
    }

    if (sectionId === ESections.EXPLORE && hasHistory) {
      sections.push(ESections.HISTORY);
    }

    if (sectionId === ESections.HISTORY && hasExplore) {
      sections.push(ESections.EXPLORE);
    }

    if (cityId === 'jekabpils') {
      sections.push(ESections.GAME);
    }

    return sections;
  };

  const BUTTONS = getButtonsIDs();

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const id = event.currentTarget.getAttribute('data-id');

    dispatch(storeData(null, C.STORE_INFO_DATA));
    dispatch(storeData(null, C.MAP_DATA));
    dispatch(storeData(null, C.EXPAND_DATA));

    navigate({ pathname: `${ROUTES.CITY}/${cityId}/${id}` });
  };

  const getButtons = (id, index) => {
    const label = homeData?.content?.[`button_${id}`];

    return (
      <Button
        color={currentCityData.content.color}
        label={label}
        key={index}
        fullWidth
        dataId={id}
        onClick={onClick}
        className={classes.buttonStyle}
      />
    );
  };

  return (
    <div className={classes.headerButtonsContainerStyle}>
      {BUTTONS.map(getButtons)}
    </div>
  );
};

export default HeaderButtons;
