import { useState, useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const useCityData = () => {
  const { cityId, sectionId, objectId } = useParams();

  const lng = useSelector((state: RootStateOrAny) => state.app.lng);
  const appData: any = useSelector(
    (state: RootStateOrAny) => state.app.appData
  );

  const currentAppData = appData?.[lng];

  const cityData = currentAppData?.cities[cityId];

  const getSectionId = () => {
    if (sectionId === 'game') {
      return 'iepazisti-pilsetu';
    }

    return sectionId;
  };

  const finalSectionId = getSectionId();

  const sectionData =
    finalSectionId &&
    cityData?.pages?.find(
      (item) => item.content.uri === `${cityId}/${finalSectionId}`
    );

  const objectData =
    objectId &&
    sectionData?.pages?.find(
      (item) => item.content.uri === `${cityId}/${sectionId}/${objectId}`
    );

  return { cityData, sectionData, objectData };
};
