import { useDrop } from 'react-dnd';

const DropElement = ({ uid, children }) => {
  const onDrop = () => {
    console.log('>>>DROP', uid);

    return { uid };
  };

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: uid,
    drop: onDrop, // () => ({ uid }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    // drop: (item) => {
    //   console.log('>>>DROP2222', item);
    // },
  }));

  const isTargetActive = canDrop && isOver;

  if (isTargetActive) {
    console.log('>>>>isTargetActive');
  }

  return (
    <div
      // style={{ width: '100px', height: '100px', zIndex: 1000000 }}
      ref={drop}
    >
      {children}
    </div>
  );
};

export default DropElement;
