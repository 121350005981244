import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import React from 'react';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import clsx from 'clsx';

interface IProps {
  color: string;
  label: string;
  fullWidth?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  dataId?: string;
  className?: string;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      buttonContainerStyle: {
        position: 'relative',
        width: `${540 / C.PIXEL_SIZE}px`,
        height: `${120 / C.PIXEL_SIZE}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: FONTS.montserrat,
        fontSize: `${36 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
        borderRadius: `${6 / C.PIXEL_SIZE}px`,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: 0.7,
            transition: '0.5s ease',
          },
        },
        marginBottom: `${20 / C.PIXEL_SIZE}px`,
        textTransform: 'uppercase',
      },
      buttonFullStyle: {
        width: '100%',
      },
    })
);

const Button = ({
  color,
  label,
  fullWidth,
  onClick,
  dataId,
  className,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const props = {
    ...(onClick ? { onClick } : {}),
    ...(dataId ? { 'data-id': dataId } : {}),
  };

  return (
    <div
      className={clsx(
        classes.buttonContainerStyle,
        fullWidth && classes.buttonFullStyle,
        className
      )}
      style={{ background: color }}
      {...props}
    >
      {label}
    </div>
  );
};

export default Button;
