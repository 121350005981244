import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import LeftPanelObjectMenuItems from './LeftPanelObjectMenuItems';
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useCityData } from '../common/hooks/useCityData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { storeData } from '../common/app/actions';

interface IProps {
  headerText?: string;
  subText?: string;
  isGame?: boolean;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      panelContainerStyle: {
        position: 'relative',
      },
      leftPanelContainerStyle: {
        position: 'relative',
        left: `-${80 / C.PIXEL_SIZE}px`,
        width: `${760 / C.PIXEL_SIZE}px`,
        minWidth: `${760 / C.PIXEL_SIZE}px`,
        height: '100%',
        display: 'flex',
        fontFamily: FONTS.montserrat,
        flexDirection: 'column',
      },
      textContainerStyle: {
        marginBottom: `${40 / C.PIXEL_SIZE}px`,
      },
      headerStyle: {
        width: '100%',
        textAlign: 'center',
        fontSize: `${48 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.bold,
        color: COLORS.white,
        marginBottom: `${40 / C.PIXEL_SIZE}px`,
        textTransform: 'uppercase',
      },
      headerLightStyle: {
        color: COLORS.aboutBackgroundDark,
      },
      subTextStyle: {
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appYellow,
        textAlign: 'center',
        marginBottom: `${40 / C.PIXEL_SIZE}px`,
      },
      subTextLightStyle: {
        color: COLORS.appPurple,
      },
    })
);

const LeftPanel = ({ headerText, subText, isGame }: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );
  const mapData = useSelector((state: RootStateOrAny) => state.app.mapData);
  const gameData = useSelector((state: RootStateOrAny) => state.app.gameData);
  const { cityData, sectionData } = useCityData();

  useEffect(() => {
    dispatch(storeData(null, C.EXPAND_DATA));
    dispatch(storeData(sectionData, C.MAP_DATA));
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!cityData || !mapData) {
    return null;
  }

  const getData = () => {
    if (isGame) {
      return gameData?.menuData;
    }

    return mapData?.pages.filter(
      (item) =>
        (item.content.template === 'pages/container' && item.pages.length) ||
        item.content.template === 'pages/article'
    );
  };

  const cityColor = cityData?.content?.color;
  const currentData = getData();

  const getContent = () => {
    return (
      <>
        <div className={classes.textContainerStyle}>
          <div
            className={clsx(
              classes.headerStyle,
              isLightTheme && classes.headerLightStyle
            )}
          >
            {headerText}
          </div>
          {subText && (
            <div
              className={clsx(
                classes.subTextStyle,
                isLightTheme && classes.subTextLightStyle
              )}
            >
              {subText}
            </div>
          )}
        </div>
        <LeftPanelObjectMenuItems
          data={currentData}
          color={cityColor}
          isGame={isGame}
        />
      </>
    );
  };

  return <div className={classes.leftPanelContainerStyle}>{getContent()}</div>;
};

export default LeftPanel;
