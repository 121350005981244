export enum Lng {
  LV = 'lv',
  LT = 'lt',
  EN = 'en',
}

export enum EmitStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ActionType {
  GET = 'GET',
  SEND = 'SEND',
}

export enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum BlockType {
  BIG = 'BIG',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export enum Color {
  GRAY = 'gray',
}

export enum Orientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum ThemeType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ESections {
  EXPLORE = 'explore',
  GAME = 'game',
  HISTORY = 'history',
  SUCCESS = 'success',
}
