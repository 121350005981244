import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import LeftPanelObjectMenuItem from './LeftPanelObjectMenuItem';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import React, { useState } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { storeData } from '../common/app/actions';

interface IProps {
  data: any;
  color: any;
  isSubDir?: boolean;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      accordionStyle: {
        background: 'none !important',
        fontFamily: FONTS.montserrat,
        boxShadow: 'unset !important',
      },
      accordionSummaryStyle: {
        fontFamily: FONTS.montserrat,
        fontSize: `${32 / C.PIXEL_SIZE}px`,
        fontWeight: FONT_WEIGHT.medium,
        lineHeight: `${42 / C.PIXEL_SIZE}px`,
        textTransform: 'uppercase',
        margin: '0 !important',
        position: 'relative',
        width: '100%',
        height: `${120 / C.PIXEL_SIZE}px`,
        minHeight: `${120 / C.PIXEL_SIZE}px`,
        background: 'none',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: (props: any) => props.color,
            transition: '0.5s ease',
          },
        },
        color: `${COLORS.white} !important`,
        boxSizing: 'border-box',
        padding: `0 ${80 / C.PIXEL_SIZE}px !important`,
        display: 'flex',
        alignItems: 'center',
        border: (props) => `1px solid ${props.color} !important`,
        marginBottom: `${23 / C.PIXEL_SIZE}px !important`,
      },
      accordionSummaryLightStyle: {
        color: `${COLORS.textBlack} !important`,
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            color: `${COLORS.white} !important`,
            transition: '0.5s ease',
          },
        },
      },
      expandedStyle: {
        background: (props: any) => `${props.color} !important`,
      },
      accordionDetailsStyle: {
        padding: '0 !important',
      },
    })
);

const LeftPanelObjectMenu = ({ data, color }: IProps): JSX.Element => {
  const classes = useStyles({ color });
  const dispatch = useDispatch();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );
  const expandData = useSelector(
    (state: RootStateOrAny) => state.app.expandData
  );

  const isExpanded = data?.content?.uri === expandData?.content?.uri;

  const onChange = (event: React.SyntheticEvent, expanded: boolean) => {
    if (expanded) {
      dispatch(storeData(data, C.EXPAND_DATA));
    } else {
      dispatch(storeData(null, C.EXPAND_DATA));
    }

    dispatch(storeData(null, C.STORE_INFO_DATA));
  };

  const getSubItems = (subItem, index) => {
    return (
      <LeftPanelObjectMenuItem
        data={subItem}
        key={index}
        nr={index + 1}
        color={color}
      />
    );
  };

  const label = data.content.title;

  return (
    <MuiAccordion
      className={classes.accordionStyle}
      expanded={isExpanded}
      onChange={onChange}
    >
      <MuiAccordionSummary
        className={clsx(
          classes.accordionSummaryStyle,
          isLightTheme && classes.accordionSummaryLightStyle,
          isExpanded && classes.expandedStyle
        )}
        color={color}
      >
        {label}
      </MuiAccordionSummary>
      <MuiAccordionDetails className={classes.accordionDetailsStyle}>
        {data?.pages?.map(getSubItems)}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default LeftPanelObjectMenu;
