import * as C from '../common/constants';
import React, { FunctionComponent, useState, MutableRefObject } from 'react';
import Keyboard from 'react-simple-keyboard';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      defaultStyle: {},
      activeStyle: {
        background: (props: any) => `${props.color} !important`,
        color: 'white',
      },
      '@global': {
        '.simple-keyboard': {
          fontFamily: FONTS.montserrat,
        },
        '.simple-keyboard.myTheme1': {
          backgroundColor: COLORS.white30,
          borderRadius: 0,
          borderBottomRightRadius: `${5 / C.PIXEL_SIZE}px`,
          borderBottomLeftRadius: `${5 / C.PIXEL_SIZE}px`,
        },
        '.simple-keyboard.myTheme1 .hg-button': {
          width: `${318 / C.PIXEL_SIZE}px`,
          fontSize: `${50 / C.PIXEL_SIZE}px`,
          height: `${210 / C.PIXEL_SIZE}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          '&:active': {
            background: (props: any) => `${props.color} !important`,
            color: 'white',
          },
        },
        '.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"]': {
          maxWidth: `${318 / C.PIXEL_SIZE}px`,
        },
        '.hg-theme-default .hg-button.hg-standardBtn': {
          width: `${318 / C.PIXEL_SIZE}px`,
        },
        '.hg-button.hg-functionBtn.hg-button-space': {
          maxWidth: `${2000 / C.PIXEL_SIZE}px`,
          width: `${2000 / C.PIXEL_SIZE}px`,
        },
      },
    })
);

interface IProps {
  onChange: (input: string) => void;
  keyboardRef: MutableRefObject<any>;
  color?: string;
}

const layout = {
  default: [
    'q w e r t y u i o p {bksp}',
    "a s d f g h j k l ' {enter}",
    'z x c v b n m ! @ # {shift}',
    '{&123} {space} {accents}',
  ],
  shift: [
    'Q W E R T Y U I O P {bksp}',
    'A S D F G H J K L " {enter}',
    'Z X C V B N M ? # % {shift}',
    '{&123} {space} {accents}',
  ],
  '&123': [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    '~ ! @ # $ ^ &amp; * ( ) {enter}',
    '` , . / - = &lt; &gt; : _ +',
    '{&123} {space} {accents}',
  ],
  accentsDefault: [
    'q w ē r t y ū ī o p {bksp}',
    "ā š d f ģ h j ķ ļ ' {enter}",
    'ž x č v b ņ m ? # % {shift}',
    '{&123} {space} {accents}',
  ],
  accentsShift: [
    'Q W Ē R T Y Ū Ī O P {bksp}',
    'Ā Š D F Ģ H J Ķ Ļ " {enter}',
    'Ž X Č V B Ņ M ? # % {shift}',
    '{&123} {space} {accents}',
  ],
};

const display = {
  '{&123}': '&123',
  '{bksp}': '⌫',
  '{enter}': '⏎',
  '{shift}': '⇧',
  '{space}': '␣',
  '{accents}': 'āčē',
};

const DiplomaKeyboardWrapper: FunctionComponent<IProps> = ({
  onChange,
  keyboardRef,
  color,
}) => {
  const classes = useStyles({ color });
  const [isShiftActive, setShift] = useState(false);
  const [specialLayout, setSpecialLayout] = useState('');

  const onKeyPress = (button: string) => {
    if (button === '{shift}') {
      setShift(!isShiftActive);
    }

    if (button === '{&123}' || button === '{accents}') {
      if (specialLayout === button) {
        setSpecialLayout('');
      } else {
        setSpecialLayout(button);
      }
    }
  };

  const isAccentsActive = specialLayout === '{accents}';
  const is123Active = specialLayout === '{&123}';

  const getLayoutName = () => {
    if (!isShiftActive && !specialLayout) {
      return 'default';
    }

    if (is123Active) {
      return '&123';
    }

    if (isShiftActive) {
      if (isAccentsActive) {
        return 'accentsShift';
      }

      return 'shift';
    }

    return 'accentsDefault';
  };

  const layoutName = getLayoutName();

  return (
    <Keyboard
      keyboardRef={(r) => (keyboardRef.current = r)}
      layoutName={layoutName}
      onChange={onChange}
      onKeyPress={onKeyPress}
      // onRender={() => console.log('Rendered')}
      theme={'hg-theme-default myTheme1'}
      layout={layout}
      // useTouchEvents
      // useMouseEvents
      preventMouseDownDefault
      preventMouseUpDefault
      display={display}
      mergeDisplay
      buttonTheme={[
        {
          class: isAccentsActive ? classes.activeStyle : classes.defaultStyle,
          buttons: '{accents}',
        },
        {
          class: isShiftActive ? classes.activeStyle : classes.defaultStyle,
          buttons: '{shift}',
        },
        {
          class: is123Active ? classes.activeStyle : classes.defaultStyle,
          buttons: '{&123}',
        },
      ]}
    />
  );
};

export default DiplomaKeyboardWrapper;
