import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import CircularProgress from '@mui/material/CircularProgress';
import Marker from './Marker';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { GoogleMap, useLoadScript, Rectangle } from '@react-google-maps/api';
import { fetchData } from '../common/app/actions';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useCityData } from '../common/hooks/useCityData';
import { storeData } from '../common/app/actions';

interface IProps {
  isGame?: boolean;
}

const useStyles = makeStyles(
  (): StyleRules =>
    createStyles({
      mapContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      centerContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '30vh',
        flex: 1,
      },
      '@global': {
        '.gm-style': {
          // pointerEvents: 'none',
        },
        '.gm-style-iw-a': {
          // opacity: (props: any) => `${props.isGame ? 0 : 1} !important`,
          // pointerEvents: (props: any) =>
          //   `${props.isGame ? 'none' : 'auto'} !important`,
          // '& > button': {
          //   display: 'none !important',
          // },
        },
        '.gm-style-moc': {
          pointerEvents: 'none !important',
        },
        // '.gm-ui-hover-effect': {
        //   display: 'none !important',
        // },
        // '.gm-style .gm-style-iw-t::after': {
        //   display: 'none !important',
        // },
      },
    })
);

const containerStyle = {
  width: '100%',
  height: '100%',
};

const MapContainer = ({ isGame }: IProps): JSX.Element => {
  const classes = useStyles({ isGame });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapData = useSelector((state: RootStateOrAny) => state.app.mapData);
  const expandData = useSelector(
    (state: RootStateOrAny) => state.app.expandData
  );
  const gameData = useSelector((state: RootStateOrAny) => state.app.gameData);

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDys7DgSGsnprPDd5h_kcYy3niIQY0meys',
    language: 'lv',
    region: 'lv',
  });

  const { cityData, sectionData } = useCityData();

  const mapCenter = {
    lat: cityData?.content?.location?.latitude || 56.50039397108623,
    lng: cityData?.content?.location?.longitude || 25.8623853072524,
  };

  const latLngBounds = {
    north: mapCenter.lat + 0.1,
    south: mapCenter.lat - 0.1,
    west: mapCenter.lng - 0.1,
    east: mapCenter.lng + 0.1,
  };

  const options = {
    streetViewControl: false,
    zoom: 10,
    minZoom: 5,
    maxZoom: 20,
    zoomControlOptions: {
      position: (window as any).google?.maps.ControlPosition.LEFT_BOTTOM, // 'right-center' ,
      style: (window as any).google?.maps.ZoomControlStyle.SMALL,
    },
    mapTypeId: 'roadmap', // maps.MapTypeId.HYBRID, // ROADMAP / SATELLITE / HYBRID / TERRAIN
    restriction: {
      latLngBounds,
      strictBounds: true,
    },
    fullscreenControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  if (!isLoaded) {
    return null;
  }

  const onMarkerClick = (event, markerData) => {
    dispatch(storeData(markerData, C.STORE_INFO_DATA));
  };

  const cityColor = cityData?.content?.color;

  const currentMapData = expandData || mapData;
  const currentData = isGame ? gameData?.mapData : currentMapData?.pages;

  return (
    <div
      className={clsx(classes.mapContainerStyle, isGame && classes.gameStyle)}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        // zoom={10}
        // onLoad={onLoad}
        options={options}
        // onUnmount={onUnmount}
      >
        {currentData?.map((markerData, index) => {
          return (
            <Marker
              key={index}
              markerData={markerData}
              onClick={onMarkerClick}
              nr={index + 1}
              color={cityColor}
              isGame={isGame}
            />
          );
        })}
        {/* <Rectangle bounds={bounds} options={{ fillColor: 'red' }} /> */}
      </GoogleMap>
    </div>
  );
};

export default React.memo(MapContainer);
