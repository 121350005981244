import * as C from '../common/constants';
import * as ROUTES from '../common/routes';
import Button from './Button';
import DiplomaKeyboardWrapper from './DiplomaKeyboardWrapper';
import Input from '@mui/material/Input';
import React, { FunctionComponent, useState, useRef } from 'react';
import queryString from 'query-string';
import useEventListener from '@use-it/event-listener';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useAppData } from '../common/hooks/useAppData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      inputContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: FONTS.montserrat,
      },
      inputStyle: {
        width: `${1920 / C.PIXEL_SIZE}px`,
        height: `${180 / C.PIXEL_SIZE}px`,
        border: `1px solid ${COLORS.darkBorder}`,
        borderRadius: `${6 / C.PIXEL_SIZE}px`,
        margin: `${242 / C.PIXEL_SIZE}px 0 ${120 / C.PIXEL_SIZE}px 0`,
        padding: `${70 / C.PIXEL_SIZE}px`,
        boxSizing: 'border-box',
      },
      buttonStyle: {
        width: `${720 / C.PIXEL_SIZE}px`,
        height: `${120 / C.PIXEL_SIZE}px`,
        margin: 0,
        whiteSpace: 'nowrap',
      },
      keyboardContainerStyle: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        // height: '880px',
        display: 'flex',
        alignItems: 'center',
        background: COLORS.textBlack60,
      },
    })
);

const DiplomaKeyboard: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const currentAppData = useAppData();
  const { cityId } = useParams();
  const isLightTheme = useSelector(
    (state: RootStateOrAny) => state.app.isLightTheme
  );

  const [input, setInput] = useState('');
  const keyboard = useRef(null);

  const onRealKeyboardChange = ({ key }) => {
    if (key === 'Backspace') {
      const newInput = input.slice(0, -1);
      setInput(newInput);
      keyboard.current.setInput(input);

      return;
    }

    if (key === 'Shift' || key === 'Dead') {
      return;
    }

    const newInput = `${input}${key}`;
    setInput(newInput);
    keyboard.current.setInput(input);
  };

  useEventListener('keydown', onRealKeyboardChange);

  if (!currentAppData) {
    return null;
  }

  const citiesData = currentAppData?.cities;
  const currentCityData = citiesData?.[cityId];
  const homeData = currentAppData?.home;
  const gameTextsData = homeData?.pages?.find(
    (item) => item.content.uid === 'game-texts'
  );
  const header = gameTextsData.content.button_get_diploma;
  const inputPlaceholder = gameTextsData.content.diploma_placeholder;
  const diplomaUrl = `${ROUTES.CITY}/${cityId}${ROUTES.DIPLOMA}`;

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // dispatch(storeData(null, C.STORE_INFO_DATA));
    // dispatch(storeData(null, C.MAP_DATA));
    // dispatch(storeData(null, C.EXPAND_DATA));

    const name = encodeURI(input);
    const searchParams = queryString.parse(search);
    const paramString = queryString.stringify({
      ...searchParams,
      name,
    });

    navigate({
      pathname: diplomaUrl,
      search: paramString,
    });
  };

  // const onRealKeyboardChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ): void => {
  //   const input = event.target.value;
  //   setInput(input);
  //   keyboard.current.setInput(input);
  // };

  const onVirtualKeyboardChange = (passedInput: string): void => {
    setInput(passedInput);
  };

  return (
    <div className={classes.inputContainerStyle}>
      <Input
        placeholder={inputPlaceholder}
        className={classes.inputStyle}
        value={input}
        // onChange={onRealKeyboardChange}
        autoFocus
        sx={{
          fontSize: '48px',
          fontWeight: FONT_WEIGHT.regular,
          fontStyle: 'italic',
          whiteSpace: 'nowrap',
          color: isLightTheme ? COLORS.textBlack : COLORS.white,
        }}
      />
      <Button
        color={currentCityData.content.color}
        label={header}
        fullWidth
        onClick={onClick}
        className={classes.buttonStyle}
      />
      <div className={classes.keyboardContainerStyle}>
        <DiplomaKeyboardWrapper
          keyboardRef={keyboard}
          onChange={onVirtualKeyboardChange}
          color={currentCityData.content.color}
        />
      </div>
    </div>
  );
};

export default DiplomaKeyboard;
